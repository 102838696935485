<template>
  <div class="w_editor">
    <!-- 富文本编辑器 -->
    <div id="w_view"></div>
  </div>
</template>

<script>
// 引入富文本
import WE from "wangeditor";
import { ApiToken } from '@/utils/ApiToken'

export default {
  name: "WEditor",
  props: {
    // 默认值
    defaultText: { type: String, default: "" },
    // 富文本更新的值
    richText: { type: String, default: "" },
  },
  data() {
    return {
      // 编辑器实例
      editor: null,
    };
  },
  watch: {
    // 监听默认值
    defaultText(nv, ov) {
      if (nv != "") {
        // this.editor.txt.html(nv);
        this.$emit("update:rich-text", nv);
      }
    },
  },
  mounted() {
    this.initEditor();
  },
  methods: {
    // 初始化编辑器
    initEditor() {
      const editor = new WE("#w_view");
      // 配置编辑器
      // editor.config.onchangeTimeout = 400; /* 配置触发 onchange 的时间频率，默认为 200ms */
      editor.config.customAlert = (err) => {
        this.$toast(err);
      };
      // 监控变化，同步更新数据
      editor.config.onchange = (newHtml) => {
        // 异步更新组件富文本值的变化
        this.$emit("update:rich-text", newHtml);
      };

      // 自定义上传图片
      editor.config.showLinkImg = false; /* 隐藏插入网络图片的功能 */
      editor.config.uploadImgServer = ApiToken.baseApi + "/Files/UploadAzureBlob";
      editor.config.uploadImgMaxLength = 1; /* 限制一次最多能传几张图片 */
      editor.config.uploadImgMaxSize = 5 * 1024 * 1024; /* 限制图片大小 */
      editor.config.uploadImgHooks = {
        customInsert: function (insertImgFn, result) {
          insertImgFn(result.data.fileUrl);
        },
      };

      // 自定义视频上传
      editor.config.uploadVideoServer =
        ApiToken.baseApi + "/Files/UploadAzureBlob";
      editor.config.showLinkVideo = false; /* 隐藏插入网络视频的功能 */
      editor.config.uploadVideoAccept = ["mp4", "wmv", "MOV", "avi", "mpeg"];
      editor.config.uploadVideoMaxSize = 100 * 1024 * 1024; // 100M
      editor.config.uploadVideoHooks = {
        customInsert: function (insertVideoFn, result) {
          insertVideoFn(result.data.fileUrl);
        },
      };
      // 创建编辑器
      editor.create();
      this.editor = editor;
    },
  },
  beforeDestroy() {
    // 销毁编辑器
    this.editor.destroy();
    this.editor = null;
  },
};
</script>
<style lang="scss">
$mobil_base: 750;

@function vw($true) {
  @return ($true / $mobil_base) * 100vw;
}
.w-e-icon-upload2 {
  font-size: 60px !important;
}
.w-e-full-screen-editor {
  height: calc(100% - 80px) !important;
  top: 80px !important;
}
.w-e-progress {
  height: 2px !important;
}
@media (max-width: 750px) {
  .w-e-panel-container {
    width: vw(300) !important;
    margin-left: vw(-150) !important;
  }
  .w-e-full-screen-editor {
    height: calc(100% - 13.3333vw) !important;
    top: 13.3333vw !important;
  }
}
</style>