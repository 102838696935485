<template>
	<div class="article_page">
		<div class="article_content">
			<p class="violet article-explain" style="">您的帖子提交后将由管理员进行审核，预计时间在1个工作日内，请在「我的发帖」里关注状态</p>
			<div class="form_item">
				<label>标题<span>*</span>：</label>
				<div class="form_input">
				<el-input class="title_input" v-model="form.title" :maxlength="50" placeholder="请输入标题(最多 50 个字)"></el-input>
				</div>
			</div>
			<div class="form_item">
				<label>简介<span>*</span>：</label>
				<div class="form_input">
				<el-input class="introduction_input" type="textarea" :maxlength="100" resize="none" v-model="form.Introduction" placeholder="请输入简介"></el-input>	
				</div>			
			</div>
			<div class="form_item">
				<label>正文<span>*</span>：</label>
				<div class="form_input">
				<WEditor :defaultText="form.textContent" :richText.sync="form.textContent"></WEditor>
				</div>
			</div>
			<div class="form_item">
				<label>产品标签<span>*</span>：</label>
				<div class="form_input">
				<el-select v-model="form.productArray" multiple placeholder="选择对应的产品标签">
					<el-option v-for="(item,index) in productTagList" :key="index" :label="item.text" :value="item.value">
					</el-option>
				</el-select>
				</div>
			</div>
			<div class="form_item">
				<label>行业标签<span>*</span>：</label>
				<div class="form_input">
				<!-- <el-select v-model="form.industryArray" multiple placeholder="选择对应的行业标签(最多选择三个)" @change="industryArray(form.industryArray)">
					<el-option v-for="(item,index) in industryTagList" :key="index" :label="item.text" :value="item.value">
					</el-option>
				</el-select> -->
				<el-cascader
                	v-model="IndustryItems"
                	:options="industryTagList"
                	placeholder="选择对应的行业标签(最多选择三个)"
                	:props="props"
                	clearable
              		@change="IndustryItem1"></el-cascader>
				</div>
			</div>
			<div class="form_item">
				<label>话题<span>*</span>：</label>
				<div class="form_input">
				<el-input class="theme_input" v-model="form.Topic" :maxlength="20" placeholder="请输入话题(最多 20 个字)"></el-input>
				</div>
			</div>	
			<div class="btn_area">
				<el-button type="info" @click="submit">保存</el-button>
				<el-button type="info" @click="publish">发布</el-button>
				<el-button @click="back">取消</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import Headers from '@/components/header.vue';
	import * as forum from '../../api/forum.js';
	import WEditor  from '@/components/WEditor/Index.vue'
	import { debounce } from "@/utils/common";

	export default {
		name: "articles",
		components: {
			Headers,
			WEditor
		},
		watch: {
			screenWidth(val) {
				this.screenWidth = val
				if (this.screenWidth < 768) {
					this.isPc = false
				} else {
					this.isPc = true
				}
			},
		},
		created() {
			this.$emit('change', 1, '写文章')
			this.$nextTick(() => {
				this.getProduct()
			})
		},
		data() {
			return {
				props: { multiple: true, value: 'value', label: 'text', children: 'list' },
				IndustryItems: [],     // !行业多选
				config: {
					initialFrameHeight: 360,
					scaleEnabled: true,
					UEDITOR_HOME_URL: 'ueditor/'
				},
				isPc: true,
				screenWidth: document.body.clientWidth,
				form: {
					"id": "",
					"title": "",
					"Introduction": '',
					"textContent": "",
					"productBaseId": 0,
					"productArray": [],
					"industryArray": [],
					"industrySecondArray": [],
					"Topic": ''
				},
				industryTagList: [],
				productTagList: [],
			};
		},
		methods: {
			IndustryItem1(){
				if(this.IndustryItems.length > 3){
					this.$toast('行业标签最多同时选择三个！')
					this.IndustryItems = this.IndustryItems.slice(0, 3)
				}
            	var arr = []
            	var arr_children = []
            	this.IndustryItems.forEach(item => {
              		arr.push(item[0])
              		arr_children.push(item[1])
            	})
				this.form.industryArray = [...new Set(arr)]
      			this.form.industrySecondArray = arr_children
            	
    		},
			ueReady() {
				window.ue.setContent(this.form.textContent)
			},
			getData() {
				forum.getDetails({
					"forumId": this.$route.query.id
				}).then(res => {
					if (res.status == 1) {
						this.form = {
							"id": this.$route.query.id,
							"title": res.data[0].title,
							"Introduction": res.data[0].introduction,
							"textContent": res.data[0].textContent,
							"productBaseId": 0,
							"productArray": JSON.parse(res.data[0].product),
							"industryArray": JSON.parse(res.data[0].industry),
							"industrySecondArray": JSON.parse(res.data[0].industrySecond),
							"Topic": res.data[0].topic
						}
						var industry = JSON.parse(res.data[0].industrySecond)
						var IndustryList = []
             			this.industryTagList.filter(item => {
               				item.list.filter(val => {
                 				if(industry.indexOf(val.value) > -1){
                   					IndustryList.push([item.value, val.value])
                				}
              			 	})
            			})
             			this.IndustryItems = IndustryList
						const setContent = document.querySelector(".w-e-text");
						setContent.innerHTML = this.form.textContent;
					}
				})
			},
			//  !行业标签多选超出提示
			industryArray(val){
				if (val.length > 3) {
					this.$toast('行业标签最多同时选择三个！')
					val.pop();
				}
			},
			getProduct() {
				forum.getProduct().then(res => {
					if (res.status == 1) {
						let productList = [];
						res.data.products.map(item =>{
							if(item.extend2 == '' || item.extend2 == '驭剑'){
								productList = productList.concat(item.list);
								return productList
							}
						})
						this.productTagList = productList;
						this.industryTagList = res.data.industrys
						this.$nextTick(() => {
							if(this.$route.query.id){
								this.getData()
							}
						})
						
					}
				})
			},
			// !发布文章
			publish(){
				if(!this.form.title){
					this.$toast({message: '请填写标题',duration: 1000,})
					return
				}
				if(!this.form.Introduction){
					this.$toast({message: '请填写简介',duration: 1000,})
					return
				}
				if(!this.form.textContent){
					this.$toast({message: '请填写正文',duration: 1000,})
					return
				}
				if(this.form.productArray.length == 0){
					this.$toast({message: '请选择产品标签',duration: 1000,})
					return
				}
				if(this.form.industryArray.length == 0){
					this.$toast({message: '请选择行业标签',duration: 1000,})
					return
				}
				if(!this.form.Topic){
					this.$toast({message: '请填写话题',duration: 1000,})
					return
				}
				debounce(()=>{
					forum.savePublish(this.form).then(res => {
						this.$toast({message: res.message,duration: 1000,})
						if(res.status == 1){
							this.$router.push('/home/forum')
						}
					})
				},2000)
			},
			submit() {
				if(!this.form.title){
					this.$toast({message: '请填写标题',duration: 1000,})
					return
				}
				if(!this.form.Introduction){
					this.$toast({message: '请填写简介',duration: 1000,})
					return
				}
				if(!this.form.textContent){
					this.$toast({message: '请填写正文',duration: 1000,})
					return
				}
				if(this.form.productArray.length == 0){
					this.$toast({message: '请选择产品标签',duration: 1000,})
					return
				}
				if(this.form.industryArray.length == 0){
					this.$toast({message: '请选择行业标签',duration: 1000,})
					return
				}
				if(!this.form.Topic){
					this.$toast({message: '请填写话题',duration: 1000,})
					return
				}
				debounce(()=>{
					if (this.$route.query.id) {
						forum.editArticle(this.form).then(res => {
							this.$toast({message: res.message,duration: 1000,})
							if(res.status == 1){
								this.cancel()
							}
						})
					} else {
						forum.saveForum(this.form).then(res => {
							this.$toast({message: res.message,duration: 1000,})
							if(res.status == 1){
								this.cancel()
							}
						})
					}
				},2000)
			},
			cancel() {
				this.form = {
					"id": "",
					"title": "",
					"Introduction": '',
					"textContent": "",	
					"productBaseId": 0,
					"productArray": [],
					"industryArray": [],
					"industrySecondArray": [],
					"Topic": ''
				}
				this.IndustryItems = []
				const setContent = document.querySelector(".w-e-text");
				setContent.innerHTML = "";
			},
			back(){
				this.$router.push("/home/forum")
			}
		}
	};
</script>

<style lang="scss" scoped>
	$mobil_base: 750;

	@function vw($true) {
		@return ($true / $mobil_base) * 100vw;
	}
	.el-cascader-menu:nth-child(even) {
   		.el-checkbox {
      		width: 100%;
      		height: 100%;
      		z-index: 10;
      		position: absolute;
    	}
		.el-cascader-node__label{
    		margin-left: 10px;
  		}
		.el-scrollbar__bar {
      		opacity: 0 !important;
    	}
  	}
	.el-scrollbar {
    	.el-scrollbar__bar {
      		opacity: 1 !important;
    	}
  	}
  	
	.article_page {
		.article_content { padding: 15px 40px 40px;border: 1px solid #E6E6E6;
			.article-explain {margin:0 0 20px 46px}
			.form_item{
				margin: 0 0 20px 0;display: flex;
				label{
					font-size: 16px;color: #545454;width: 100px;text-align: right;line-height: 40px;
					span{color:#f60000;}
				}
				.form_input{
					width: calc( 100% - 100px );
				}
			}
			.el-cascader{ width: 100%; }
			/deep/ .el-input.title_input {
				width: 100%;
				height: 40px !important;
				line-height: 40px !important;

				input {
					height: 40px !important;
					line-height: 40px !important;
				}
			}
			
			/deep/ .el-textarea.introduction_input{
				font-size: 16px;
			}
			

			/deep/ .el-select {
				width: 100%;
				min-height: 30px;
				border-radius: 0;

				.el-input {
					input {
						font-size: 16px;
						color: #545454;
					}
					.el-input__icon{
						line-height: 30px;
					}
				}
			}
			
			/deep/ .el-input.theme_input {
				width: 100%;
				height: 30px !important;
				line-height: 30px !important;
			
				input {
					font-size: 16px;
					color: #545454;
				}
			}

			.btn_area {
				margin-top: 140px;
				display: flex;
				justify-content: flex-end;

				/deep/ .el-button {
					width: 156px;
					height: 36px;
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 0;

					span {
						font-size: 16px;
					}

					&:first-of-type {
						background: #6B18A8;
					}
					&:nth-of-type(2) {
						background: #6B18A8;
					}
				}
			}
		}
	}

	@media (max-width: 750px) {
		.article_page {
			margin: vw(35) 0 vw(64);
			
			.article_content {
				padding: vw(40);border: vw(1) solid #E6E6E6;
				.article-explain {margin: 0; margin-bottom: 10px;}
				.form_item{
					margin: 0 0 vw(20) 0;
					label{
						font-size: vw(24);
						width: vw(144);
					}
					&:first-of-type{
						label{
							line-height: vw(60);
						}
					}
					&:nth-of-type(2){
						label{
							padding: vw(16) 0 0 0;
						}
					}
					&:nth-of-type(3){
						label{
							padding: vw(16) 0 0 0;
						}
					}
					&:nth-of-type(4){
						label{
							line-height: vw(70);
						}
					}
					&:nth-of-type(5){
						label{
							line-height: vw(70);
						}
					}
					&:nth-of-type(6){
						label{
							line-height: vw(70);
						}
					}
					.form_input{
						width: calc( 100% - 72px );
					}
				}
			
				/deep/ .el-input.title_input {
					height: vw(60) !important;
					line-height: vw(60) !important;
			
					input {
						height: vw(60) !important;
						line-height: vw(60) !important;
						font-size: vw(24);
					}
				}
				
				/deep/ .el-textarea.introduction_input{
					font-size: vw(24);
				}
			
				/deep/ .el-select {
			
					.el-input {
						height: auto !important;
						input {
							font-size: vw(24);
						}
						.el-input__icon{
							line-height: vw(70);
						}
					}
				}
				/deep/ .el-input.theme_input {
					height: vw(70) !important;
					line-height: vw(70) !important;
							
					input {
						height: vw(70) !important;
						line-height: vw(70) !important;
						font-size: vw(24);
					}
				}
			
				.btn_area {
					margin-top: vw(240);
					justify-content: center;
			
					/deep/ .el-button {
						width: vw(200);
						height: vw(66);
			
						span {
							font-size: vw(24);
						}
			
						&:first-of-type {
							background: #6B18A8;
						}
			
						&:nth-of-type(2) {
							background: #6B18A8;
						}
					}
				}
			}
		}
	}
</style>
<style>
	#tinymce p {white-space: nowrap!important;}
	#edui1_bottombar{display: none!important;}
	.mce-container, .mce-container *, .mce-widget, .mce-widget *, .mce-reset {white-space: normal!important;}
	.mce-statusbar{display: none!important;}
	.mce-reset{width: 100%!important;}
	.mce-txt{display: inline-block;min-width: 40px;text-align: center!important;}
</style>
